<template>
  <div class="h-full">
    <router-view />
  </div>
</template>
<style>
html { height: 100%; overflow:auto; }
body.kartu { height: 100%; }

*{
  box-sizing: border-box;
}
#app{
  height: 100%;
}

body{
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #7023ec;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 23, 172); 
}

.swiper-wrapper{
  align-items: center;
}

@media (max-width: 768px){
  .swiper-button-next, .swiper-button-prev{
    display: none !important;
  }
}

@media screen and (min-width: 1px){
  body{
    overflow-y: hidden;
  }
}

#beritatemplate .swiper-pagination-bullet-active{
  background: #9333ea !important;
}

#landingtemplate .swiper-pagination-bullet-active{
  background: rgba(255,255,255,0.7) !important;
}

.video{
  position: relative;
  padding: 3px 0;
}

.videoDetail{
  padding: 3px 5px;
}

.video a{
  background: linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.3)),url('@/assets/play-button.png');
  height: 95%;
  width: 95%;
  position: absolute;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center ;
}

@media (max-width: 1080px) {
  .video a{
    height: 98%;
    width: 98%;
  }
}

@media (max-width: 576px) {
  .video a{
    height: 95%;
    width: 95%;
  }
}
</style>
<script>
export default{
  mounted(){
    console.log(process.env.NODE_ENV)
  }
}
</script>