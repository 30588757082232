<template v-if="contents">
  <div>
    <swiper :modules="modules"
      :slides-per-view="1"
      :loop="true"
      ref="swiperfaq"
      :navigation="{
        nextEl: '.testi-next',
        prevEl: '.testi-prev'
      }"
      :breakpoints="swiperOptions.breakpoints"
      :autoplay="{ delay: 3500, disableOnInteraction: false }"
      @slideChange="onSlideChange" class="py-1 flex justify-center items-center w-full flex flex-wrap relative py-6 lg:h-80 sm:h-45 md:h-60 content-center bg-cover bg-no-repeat bg-center" :style="{backgroundImage: `linear-gradient(to bottom left, rgba(0,0,0,.8), rgba(0,0,0,.7)), url('${image}')`}">
      <swiper-slide v-for="content in contents" class="flex justify-center items-center testimonial-item p-2" :key="content.id">
        <div class="lg:w-6/12 md:w-4/6 p-4">
            <div class="lg:pr-4">
                <p class="xxs:text-xl text-left md:text-2xl text-white sm:font-semibold font-bold lg:text-4xl xs:mb-6 sm:mb-6 md:mb-8 lg:mb-12">{{content.title}}</p>
                <span class="sm:text-justify text-white lg:text-left sm:text-base xxs:text-xs" v-html="content.description"></span>
            </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

export default {
  props: ['contents', 'headertitle', 'color1', 'color2', 'color3', 'image'],
  components: {
    Swiper,
    SwiperSlide
  },
  data(){
    return{
      slidesPerView: 1,
      spaceBetween: 0,
      // Responsive breakpoints
      swiperOptions: {
          breakpoints: {       
            320: {       
              slidesPerView: 1,
              spaceBetween: 0     
            },          
            640: {       
              slidesPerView: 1,       
              spaceBetween: 0     
            },   
            771: {       
              slidesPerView: 1,       
              spaceBetween: 0     
            } 
        }
      },
    }
  },
  setup() {
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
}
</script>

<style scoped>
.viewheight{
  height: 90vh;
}
</style>