<template v-if="!!kontak">
<footer id="footer" class="bg-gray-100 text-center lg:text-left">
  <div class="p-3 text-gray-800">
    <div class="grid md:grid-cols-3 xxs:grid-cols-1 gap-4 md:gap-0">
      <div class="xxs:mb-2 md:mb-0">
          <center>
            <img :src="kontak.foto" class="md:h-32 md:h-full md:p-12 sm:h-1/2 sm:p-8 xxs:h-32" alt="Logo KSD" />
          </center>
      </div>
      <div class="xxs:mb-2 md:mb-0 text-center xxs:flex xxs:flex-wrap items-center xxs:justify-center">
        
          <div>
            <!-- Contact us-->
            <div class="mb-10 xxs:flex xxs:flex-wrap sm:block">
              <h5 class="font-medium mb-2 uppercase lg:text-xl xxs:text-sm md:text-base xxs:pr-1 xxs:text-center w-full">Contact Us</h5>
              <div class="flex flex-wrap justify-center items-center sm:w-full m-auto xxs:w-2/3">
                <div class="hover:cursor-pointer flex justify-start w-8 h-8 mb-2 mr-2">
                  <a :href="'https://wa.me/'+kontak.no_hp"><img src="@/assets/whatsapp.png" class="w-full h-full" alt=""></a>
                </div>
                <div class="hover:cursor-pointer flex justify-start w-8 h-8 mb-2 mr-2">
                  <a :href="`mailto:${kontak.email}`" class="w-full text-left" target="_blank"><img src="@/assets/gmail.png" class="w-full h-full" alt=""></a>
                </div>
                <div class="hover:cursor-pointer flex justify-start w-8 h-8 mb-2 mr-2">
                  <a :href="kontak.youtube" class="w-full text-left" target="_blank"><img src="@/assets/youtube.png" class="w-full h-full" alt=""></a>
                </div>
                <div class="hover:cursor-pointer flex justify-start w-8 h-8 mb-2">
                  <a :href="kontak.instagram" class="w-full text-left" target="_blank"><img src="@/assets/instagram.png" class="w-full h-full" alt=""></a>
                </div>
              </div>
            </div>
            
            <h5 class="font-medium mb-2 uppercase lg:text-xl xxs:text-sm md:text-base md:block xxs:flex xxs:justify-center text-center w-full">
              General Information
            </h5>
            <p class="mb-2 xxs:w-full md:block sm:flex sm:justify-center">
              Jam Operasional<br>Senin-Sabtu<br>8:30 - 17:00
            </p>
          </div>
      </div>
      <div class="xxs:mb-2 md:mb-0 text-center flex flex-col justify-center">
        <h5 class="font-medium uppercase lg:text-xl xxs:text-sm md:text-base xxs:flex xxs:justify-center">Lokasi Kantor</h5>
        <p class="mb-4 xxs:flex xxs:justify-center md:w-full">{{ kontak.alamat }}</p>
        <div class="flex justify-center wrapper w-full">
          <span v-html="kontak.linkAlamat"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center items-center xxs:text-xs md:text-base text-white p-4" style="background: #8D41F2">
    © 2021 Copyright:
    <a class="text-white" href="https://tailwind-elements.com/">Kita Serba Digital</a>
  </div>
</footer>   
</template>
<script>
export default{
  props: ['kontak']
}
</script>
<style>
  .wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }
  .wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>