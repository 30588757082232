<template>
  <div>
    <div ref="containerref" class="button-container block xxs:py-4 md:py-8 lg:py-12">
      <h1 class="overflow-y-hidden xxs:py-4 sm:py-8 m-auto text-center text-white font-bold lg:text-4xl md:text-2xl sm:text-3xl xxs:text-xl">{{headertitle}}</h1>

      <Splide class="w-full" :options="options" aria-label="My Favorite Images">
        <SplideSlide class="flex justify-center items-center" v-for="mitra in mitras" :key="mitra.id">
          <div class="mitra-img">
            <img :src="mitra.image" alt="Sample 1">
          </div>
        </SplideSlide>
      </Splide>

    </div>
  </div>

</template>

<style scoped>
@import url('@/assets/css/bootstrap.min.css');
@import url('@/assets/css/all.min.css');
@import url('@/assets/css/animate.css');
@import url('@/assets/css/nice-select.css');
@import url('@/assets/css/owl.min.css');
@import url('@/assets/css/magnific-popup.css');
@import url('@/assets/css/flaticon.css');
@import url('@/assets/css/main.css');
@import url('@splidejs/splide/dist/css/splide.min.css');

.splide{
  max-width: 100%!important;
  padding: 20px 0;
}

.button-container{
  position: relative;
}

.splide__arrows,.splide__arrows--ltr{
  display: none;
}

.mitra-img{
  max-width: 390px;
  aspect-ratio: auto 3/2;
  display: flex;
  align-items: center;
}

.mitra-img img{
  width: 100%;
  height: 100%;
  aspect-ratio: auto 3/2;
  object-fit: contain;
}
</style>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import {defineComponent} from 'vue';

export default defineComponent({
  props: ['headertitle', 'color1', 'color2', 'color3', 'mitras'],
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      options: {
        type: 'loop',
        autoplay: true,
        rewind:true,
        perMove: 1,
        width: 800,
        gap: '1rem',
        perPage: 4,
        interval: '1500',
        focus: 'center',
        breakpoints: {
          760: {
            arrows: false,
            perPage: 2
          },
          1080: {
            perPage: 3,
          },
        }
      }
    }
  },
  methods: {
    changeCarouselBg(){
      this.$refs.containerref.style.background = 'linear-gradient(16deg, '+this.color1+' 0%, '+this.color2+' 65%, '+this.color3+' 100%)'
    },
  },
  mounted(){
    this.changeCarouselBg()
  }
})
</script>