<template v-if="contents">
  <div id="product">
    <div ref="containerref" class="button-container block xxs:py-4 md:py-8 lg:py-12">
      <h1 class="overflow-y-hidden font-bold xxs:pt-4 sm:py-8 m-auto text-center text-white lg:text-4xl md:text-2xl sm:text-3xl xxs:text-xl">{{headertitle}}</h1>
      <swiper :modules="modules"
        :slides-per-view="3"
        :space-between="50"
        :loop="true"
        navigation
        :pagination="{ clickable: true }"
        :breakpoints="swiperOptions.breakpoints"
        :autoplay="{ delay: 3500, disableOnInteraction: false }"
        @slideChange="onSlideChange" class="py-2 flex justify-center items-center">
        <swiper-slide v-for="content in contents" class="flex justify-center" :key="content.id">
            <img :src="content.image" alt="" class="w-7/12 hover:cursor-pointer" @click="openModalNew(content.image, content.name, content.deskripsi, content.link)">
        </swiper-slide>
      </swiper>
    </div>
    <div id="modal-container" @click="closeModal()" style="z-index: 999;">
      <div class="modal-background">
        <div class="modal xxs:p-2 sm:p-5" style="height: 500px">
          <div class="flex items-center w-full h-full" >
            <div class="muncul md:flex justify-center items-center sm:overflow-y-hidden xxs:overflow-y-scroll" style="z-index: 9999;">
              <div class="kiri md:w-1/2 xxs:w-full md:mb-0 xxs:mb-3 md:h-5/6 xxs:h-25 mr-4" style="overflow-y: hidden;">
                <img :src="temporaryimage" style="display: block; margin: 0 auto;" class="w-100 h-auto" alt="logo">
              </div>
              <div class="kanan md:w-1/2 xxs:w-full" style="overflow-y: hidden;">
                <h1 style="font-weight: bold" class="md:text-left xxs:text-center lg:text-3xl md:text-2xl xxs:text-lg">{{temporarynama}}</h1>
                <div class="mb-4 md:text-left xxs:text-center xxs:text-xs md:text-base xl:text-lg">{{temporarydesc}}</div>
                <button class="buttoncek overflow-y-hidden xxs:text-sm md:text-base " @click="gotopage(temporarylink)">Cek Sekarang!</button>
              </div>
            </div>
            <svg id="modal-svg-id" class="modal-svg" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none">
              <rect id="modal-path" x="0" y="0" fill="none" width="100%" height="100%" rx="3" ry="3"></rect>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
@import url('@/assets/css/bootstrap.min.css');
@import url('@/assets/css/all.min.css');
@import url('@/assets/css/animate.css');
@import url('@/assets/css/nice-select.css');
@import url('@/assets/css/owl.min.css');
@import url('@/assets/css/magnific-popup.css');
@import url('@/assets/css/flaticon.css');
@import url('@/assets/css/main.css');
@import url('@/assets/css/modal.css');

*{
  overflow-x: hidden;
}

::root{
  --swiper-theme-color: #fff;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background: #7023ec;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 23, 172); 
}

.nextbutton img, .prevbutton img{
  background: #fff;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: 0 -20%;
}

.nextbutton, .prevbutton{
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  transition: 0.5s;
  color: white;
}

.nextbutton{
  left: 20px;
}

.prevbutton{
  right: 20px;
}

.swiper-button-next, .swiper-button-prev{
  color: white!important;
}

.button-container{
  position: relative;
}
.modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  width:100%;
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  background: white;
}
.modal-window header {
  font-weight: bold;
}
.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}
  .modal-close:hover {
    color: black;
  }

/* Demo Styles */

html,
body {
  height: 100%;
}

html {
  font-size: 18px;
  line-height: 1.4;
}

body {
  font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 600;
  background-image: linear-gradient(to right, #7f53ac 0, #657ced 100%);
  color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal-window > div {
  border-radius: 1rem;
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

.logo {
  max-width: 150px;
  display: block;
}

small {
  color: lightgray;
}

.btn {
  background-color: white;
  padding: 1em 1.5em;
  border-radius: 0.5rem;
  text-decoration: none;
}
  .btn i {
    padding-right: 0.3em;
  }

.buttoncek{
  border: 2px solid #9333EA;
  border-radius: 9999px;
  color: #9333EA;
  transition: 0.5s;
}

.buttoncek:hover{
  cursor: pointer;
  background: #9333EA;
  color: white;
}

#modal-container .modal-background .modal {
  padding: 25px;
}


@media (max-width:576px){
  #modal-container .modal-background .modal {
    padding: 15px;
  }
}

@media (max-width:480px){
  #modal-container .modal-background .modal {
    padding: 10px;
  }
}

</style>

<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

export default {
  props: ['contents', 'headertitle', 'color1', 'color2', 'color3'],
  components: {
    Swiper,
    SwiperSlide
  },
  data(){
    return{
      slidesPerView: 3,
      spaceBetween: 30,
      // Responsive breakpoints
      swiperOptions: {
          breakpoints: {       
            0: {       
              slidesPerView: 1,
              spaceBetween: 10     
            },          
            640: {       
              slidesPerView: 2,       
              spaceBetween: 50     
            },   
            771: {       
              slidesPerView: 3,       
              spaceBetween: 30     
            } 
        
        }
      },
      temporarynama: '',
      temporarylink: '',
      temporarydesc: '',
      temporaryimage: '',
    }
  },
  methods: {
    changeCarouselBg(){
      this.$refs.containerref.style.background = 'linear-gradient(16deg, '+this.color1+' 0%, '+this.color2+' 65%, '+this.color3+' 100%)'
    },
    nextFunction() {
      this.$refs.carouselproduct.next()
    },
    prevFunction() {
      this.$refs.carouselproduct.prev()
    },
    movePage(link){
      window.open(link, '_blank');
    },
    openModalNew(image, name, deskripsi, link){
      this.temporarydesc = (deskripsi.length <= 200) ? deskripsi.substring(0, 200) : deskripsi.substring(0, 200) + '...';
      this.temporarynama = name;
      this.temporaryimage = image;
      this.temporarylink = link;

      document.getElementById('modal-container').classList.remove('six');
      document.getElementById('modal-container').classList.remove('out');
      document.getElementById('modal-container').classList.add('six');
      document.body.classList.add('modal-active');
      
      var horizontals = document.querySelectorAll('#modal-path');
      // var horizontalid = document.getElementById('modal-svg-id');
      // var widthvar = horizontalid.style.width;
      // var heightvar = horizontalid.style.height;

      for (var i = 0; i < horizontals.length; i++ ) {
          var path = horizontals[i],
              length = path.getTotalLength();

          path.style.strokeDasharray = length;
          path.style.strokeDashoffset = length; 
          // path.setAttribute('height', heightvar);
          // path.setAttribute('width', widthvar);
      }
    },
    closeModal(){
      document.getElementById('modal-container').classList.add('out');
      document.body.classList.remove('modal-active');
    },
    gotopage(link){
      window.open(link, '_blank').focus();
      return false;
    }
  },
  setup() {
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  mounted(){
    this.changeCarouselBg()
  },  
}
</script>