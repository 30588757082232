<template v-if="!!tentangKami">
    <div id="about" class=" rounded-t-lg mt-lg-n2 md:py-12 xxs:py-6 lg:px-8 xxs:px-4 m-auto justify-center">
        <!-- <p class="font-bold lg:text-4xl md:text-3xl xxs:text-2xl text-center pb-8" style="color: #8D41F2">Tentang Kami</p> -->
        <div class="md:px-5 lg:px-4 md:flex">
            <div class="md:w-1/3 xxs:w-full md:flex xxs:hidden items-center">
                <img :src="tentangKami.image" class="h-auto w-full" alt="Siapa?"/>
            </div>
            <div class="md:w-2/3 xxs:w-full xxs:px-3 flex items-center">
                <div>
                    <p class="font-bold lg:text-4xl md:text-2xl sm:text-3xl xxs:text-xl md:py-4 xxs:py-2 xxs:pt-3 px-1 xxs:text-xl capitalize" style="color: #8D41F2">{{ tentangKami.judul }}</p>
                    <div class=" text-justify capitalize md:px-2">
                        <span class="break-words deskripsi xxs:text-xs md:text-base 2xl:text-lg" v-html="tentangKami.deskripsi"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .deskripsi /deep/ p{
        font-size: 16px;
        line-height: 1.25rem;
    }

    @media (max-width: 576px) {
        .deskripsi /deep/ p{
            font-size: 12px!important;
        }
    }

    @media (min-width: 1400px){
        .deskripsi /deep/ p{
            font-size: 20px!important;
            line-height: 1.5rem;
        }
    }

    @media (min-width: 1560px){
        .deskripsi /deep/ p{
            font-size: 22px!important;
            line-height: 2rem;
        }
    }
</style>

<script>
export default{
    props: ['tentangKami'],
}
</script>