<template v-if="!!galleryItems">
    <div id="gallery">
        <p class="sm:pt-8 md:pt-12 m-auto text-center xxs:pt-4 font-bold lg:text-4xl md:text-2xl sm:text-3xl xxs:text-xl" style="color: #8D41F2">Galeri</p>
        <div class="flex flex-wrap my-2 justify-center items-center">
            <div ref="iframeref" class="lg:w-5/12 md:w-3/6 p-3 w-full gallery-item lg:p-8 xxs:p-2 md:flex md:flex-wrap md:justify-center">
                <div ref="iframeref2" class="flex items-center w-full h-full">
                    <img ref="leftimage" src="" alt="Gambar" class="xxs:w-full md:w-3/4 m-auto">
                    <iframe ref="leftlink" class="w-full xs:h-64 sm:h-72 xl:h-96 m-auto"></iframe>
                </div>
            </div>
            <div class="flex md:px-8 xxs:px-2 py-1 md:flex-wrap lg:w-7/12 md:w-1/2 xxs:w-auto xxs:overflow-x-scroll md:overflow-hidden">
                <div class="lg:w-1/3 md:w-1/2 md:flex xxs:px-2 md:flex-wrap hover:border-4 transition-all duration-100 hover:cursor-pointer border-purple-600 justify-center" v-for="galleryitem in galleryItems" :key="galleryitem.id" ref="galleryitem" @click="changeActive(galleryitem.id)">
                    <div class="md:p-2 rounded-lg gallery-item xxs:w-32 sm:w-32 flex flex-wrap items-center ease-in-out lg:w-full" v-if="galleryitem.type == 'image'" @click="changeGalleryItem(galleryitem.src, galleryitem.type)" >
                        <img class="w-full" :src="galleryitem.src" alt="src" >
                    </div>
                    <div class="rounded-lg xxs:w-32 gallery-item md:w-full md:px-2 lg:w-full flex flex-wrap items-center ease-in-out h-full video" v-else-if="galleryitem.type == 'video'" @click="changeGalleryItem(galleryitem.src, galleryitem.type)" >
                        <img class="w-full" :src="'http://img.youtube.com/vi/'+galleryitem.videoid+'/default.jpg'" alt="">
                        <a href="javascript: void(0);"></a>
                    </div>
                </div>
                <div class="lg:w-1/3 md:w-1/2 h-full p-2 xxs:justify-center items-center duration-100 hover:cursor-pointer hover:opacity-80 ease-in-out md:text-sm">
                    <router-link :to="{ name: 'Album' }" class="xxs:w-32 md:w-full gallery-item md:text-sm xl:text-base text-white xxs:text-xs flex items-center justify-center rounded-sm" style="background: linear-gradient(to left bottom, #ac7df1, #777)">
                        More >>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['galleryItems'],
    data(){
        return{
            dataGallery: []
        }
    },
    methods: {
        changeGalleryItem(newsrc, type){
            this.$refs.leftimage.style.display = "none"
            this.$refs.leftlink.style.display = "none"
            this.$refs.iframeref2.style.height = "100%"
            if(type=="image"){
                this.$refs.leftimage.src = newsrc
                this.$refs.leftimage.style.display = "block"
            }else{
                this.$refs.leftlink.src = newsrc
                this.$refs.leftlink.style.display = "block"
                this.$refs.iframeref2.style.height = "100%"
            }
        },
        getDataGallery(){
            this.dataGallery = JSON.stringify(this.galleryItems)
        },
        updateGalleryImage(){
            this.$refs.leftimage.style.display = "none"
            this.$refs.leftlink.style.display = "none"
            if(this.galleryItems[0].type == "image"){
                this.$refs.leftimage.style.display = "block"
                this.$refs.leftimage.src = this.galleryItems[0].src
            }else{
                this.$refs.leftlink.style.display = "block"
                this.$refs.leftlink.src = this.galleryItems[0].src
            }
        },
        changeActive(index){
            for(let x=0; x<this.$refs.galleryitem.length; x++){
                this.$refs.galleryitem[x].classList.remove('activeGallery');
            }
            this.$refs.galleryitem[index].classList.add('activeGallery')
        }
    },
    updated(){
        this.updateGalleryImage()
    },
}
</script>


<style scoped>

iframe video{
    width: 100%;
    height:auto;
}

.activeGallery{
    border: 3px solid #9333EA;
}

@media (min-width: 640px){
    .sm\:gallerysm{
        height: 900px;
    }
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #7e22ce;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7e22ce;
}

@media (max-width:768px){
  .video a{
    width:100%;
  }
}

.gallery-item{
    aspect-ratio: auto 1/1;
    object-fit: contain;
}
</style>