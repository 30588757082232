<template v-if="!!beritaLain && !!berita">
  <div class="mb-8">
    <div class="flex items-center w-auto xxs:h-10 md:h-16 xxs:ml-2 xxs:my-2 md:ml-8">
      <img src="@/assets/arrow.png" class="w-6 h-6 mr-2" alt="">
      <router-link :to="{ path: '/berita' }" href="#" class="lg:text-xl xxs:text-sm font-bold">
        Back to Home
      </router-link>
    </div>
    <div class="lg:flex md:flex sm:mx-8 xxs:mx-4 lg:mx-20 md:mx-16">
      <div class="lg:w-8/12 md:w-8/12 lg:mr-16 md:mr-12 sm:mb-12 xxs:mb-8 xxs:mx-2">
        <p class="text-left py-2 xl:text-3xl md:text-2xl sm:text-2xl xxs:text-lg font-bold text-slate-700 break-words title-news">{{ berita.title }}</p>
        <p class="xxs:text-sm sm:text-base md:text-sm font-semibold">{{ berita.datetime }}</p>
        <div class="berita-img">
          <img :src="berita.image" class="w-full xxs:my-4" alt="">
        </div>
        <span class="break-words deskripsi" v-html="berita.description"></span>
      </div>
      <div class="lg:w-3/12 md:w-4/12">
        <div class="beritalain w-full overflow-y-scroll pb-8" >
          <p class="xxs:text-lg md:text-xl text-center font-bold border-b-4 xxs:pb-0 md:pb-2 border-purple-600 text-slate-700">Berita Lainnya</p>
          <div class="relative p-2" v-for="berita in beritaLain.slice(0,3)" :key="berita.id" @click="beritaDetails(berita.id, berita.slug)">
            <img :src="berita.image" alt="" class="w-full berita-img">
            <div class="hover:cursor-pointer lg:p-4 xxs:p-2 absolute left-0 top-0 w-full h-full opacity-0 hover:opacity-100 transition-all duration-300">
              <div class="relative w-full h-full">
                <div class="absolute bottom-0 left-0">
                  <p class="font-semibold text-white z-10 relative sm:text-lg xxs:text-sm lg:text-base md:text-xs xxs:mb-2 md:mb-0 title-news text-left" :title="berita.title">{{ berita.title }}</p>
                  <p class="text-white z-10 relative lg:text-sm md:text-xs sm:text-base xxs:text-xs text-left">{{berita.date}}</p>
                </div>
              </div>
              <div class="bg-black z-0 opacity-50 w-full h-full absolute left-0 top-0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.beritalain::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.deskripsi >>> p{
  margin: 10px 0!important;
}

.berita-img{
  object-fit: contain;
  aspect-ratio: auto 3/2;
}
@media (max-width: 578px) {
  .deskripsi /deep/ p{
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .deskripsi /deep/ p{
    font-size: 16px;
  }
}
</style>

<script>
export default {
  props: ['beritaLain', 'berita'],
  methods: {
    beritaDetails(id, slug){
      localStorage.idBerita = id;
      this.$router.push({name: 'BeritaDetails', params: {id,slug}})
      this.$router.go(0)
    },
  }
}
</script>

<style scoped>
.title-news{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>