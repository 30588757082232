<template>
  <nav
    class="purpletheme relative z-10 border-gray-200 px-2 sm:px-4 py-2.5 drop-shadow-md" :style="navbarstyle"
  >
    <div class="flex flex-wrap justify-between items-center lg:mx-auto">
      <router-link :to="{ path: '/home' }" href="#" class="flex items-center justify-center md:w-1/6">
        <img src="@/assets/logoputih.png" class="mr-3 h-10 sm:h-16" alt="Logo KSD" />
      </router-link>
      <button id="openNav" class="inline-flex items-center p-2 ml-3 text-sm xxs:mr-0 rounded-lg md:hidden focus:outline-none focus:ring-2 text-grey-400 hover:bg-white focus:ring-white focus:text-purple-600 focus:bg-white hover:text-purple-600" style="width: auto !important" @click="stopOverflow">
        <svg
          class="w-6 h-6"
          fill="currentColor"
          id="menu"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <div class="w-full md:block xxs:hidden md:w-5/6">
        <ul
          class="navbarcontainer flex transition-all duration-200 items-center justify-end sm:w-full flex-col md:flex-row md:mt-0 md:text-sm md:font-medium"
        >
          <li id="navbarberita" class="navbaritem xxs:w-full sm:w-full md:w-auto p-0">
            <router-link :to="{ path: '/' }" class="block text-center py-2 pr-4 pl-3 border-b border-gray-300 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-white md:p-0 text-white lg:text-lg hover:font-bold">
                Home
              </router-link>
          </li>
          <li id="navbarberita" class="navbaritem xxs:w-full sm:w-full md:w-auto p-0">
            <router-link :to="{ name: 'Berita' }" class="block text-center py-2 pr-4 pl-3 border-b border-gray-300 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-white md:p-0 text-white lg:text-lg hover:font-bold">
                Berita
              </router-link>
          </li>
          <li id="navbaralbum" class="navbaritem xxs:w-full sm:w-full md:w-auto p-0">
            <router-link :to="{ name: 'Album' }" class="block text-center py-2 pr-4 pl-3 border-b border-gray-300 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-white md:p-0 text-white lg:text-lg hover:font-bold">
                Album
              </router-link>
          </li>
          <li id="navbarourteam" class="navbaritem xxs:w-full sm:w-full md:w-auto p-0">
            <router-link :to="{ name: 'OurTeam' }" class="block text-center py-2 pr-4 pl-3 border-b border-gray-300 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-white md:p-0 text-white lg:text-lg hover:font-bold">
                Our Team
              </router-link>
          </li>
          <li id="navbarmeetingmanagement" class="navbaritem xxs:w-full sm:w-full md:w-auto p-0">
            <router-link :to="{ name: 'MeetingManagement' }" class="block text-center py-2 pr-4 pl-3 border-b border-gray-300 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-white md:p-0 text-white lg:text-lg hover:font-bold">
                Meeting Management
              </router-link>
          </li>
        </ul>
      </div>

    </div>
  </nav>

  <div id="mobile-menu" class="navbar hidden">
    <ul>
      <li id="home" class="navbaritem mobile-list">
        <router-link :to="{ path: '/home' }" href="#"
          class="outer-navbutton" :class="$route.name == 'Landing' ? 'activeNav' : '' " @click="movescreen('home')">
          Home
        </router-link>
      </li>
      <li id="berita" class="navbaritem mobile-list">
        <router-link :to="{ name: 'Berita' }" :class="$route.name == 'Berita' ? 'activeNav' : '' " class="outer-navbutton" >
            Berita
          </router-link>
      </li>
      <li id="album" class="navbaritem mobile-list">
        <router-link :to="{ name: 'Album' }" :class="$route.name == 'Album' ? 'activeNav' : '' " class="outer-navbutton" >
            Album
          </router-link>
      </li>
      <li id="ourteam" class="navbaritem mobile-list">
        <router-link :class="$route.name == 'OurTeam' ? 'activeNav' : '' " :to="{ name: 'OurTeam' }" class="outer-navbutton" >
            Our Team
          </router-link>
      </li>
      <li id="meetingmanagement" class="navbaritem mobile-list">
        <router-link :class="$route.name == 'MeetingManagement' ? 'activeNav' : '' " :to="{ name: 'MeetingManagement' }" class="outer-navbutton">
            Meeting Management
          </router-link>
      </li>
    </ul>
    <div class="icon" @click="stopOverflow"><img src="@/assets/x-icon-white.png" alt=""></div>
  </div>
</template>

<style scoped>
.whitetheme .activenavbar > a{
  color: #9333ea;
}

.whitetheme .activenavbar{
  color: #9333ea;
}

.purpletheme .activenavbar > a{
  font-weight: 900;
}

.navbarcontainer{
  line-height: 3;
  transition: 1s;
}

.activeNav{
  font-weight: 700;
  color: #8D41F2;
}

.icon{
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  width: 30px;
  height: 30px;
  padding: 5px;
}

#mobile-menu{
  position: fixed;
  overflow-y: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.7);
  z-index: 999;
  padding: 5px;
}

.navbar{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

#mobile-menu ul{
  display: block;
  position: absolute;
  top: 55px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  padding: 10px 0;
  border-radius: 5px;
  background-color: #f8f9fa;
}

#mobile-menu .mobile-list{
  width: 100%;
  padding: 0;
}

#mobile-menu .mobile-list a{
  width: 100%;
  padding: 10px 20px;
  margin: 0;
}

</style>

<script>
export default {
  name: "Navbar",
  props: ['navbaron', 'whitetheme'],
  data(){
    return{
      navbarvariable: true,
      navbarstyle: 'background: #8D41F2',
    }
  },
  methods: {
    movescreen(id){
      if(document.getElementById(id) != null){
        var scrollDiv = document.getElementById(id).offsetTop;
        console.log(scrollDiv)
        window.scrollTo(0, scrollDiv);
        var listnavbar = document.getElementsByClassName('navbaritem')
        for(let x=0; x < listnavbar.length; x++){
          listnavbar[x].classList.remove('activenavbar');
        }
        document.getElementById('navbar'+id).classList.add('activenavbar');
        document.querySelector('html').classList.remove('overflow-y-hidden');
      }
    },
    setnavbar(element){
      if(element != 'home'){
        this.navbarvariable = false;
      }else{
        this.navbarvariable = true;
      }
      if(document.getElementById('navbar'+element)){
        document.getElementById('navbar'+element).classList.add('activenavbar');
      }
    },
    moveup(){
      this.$nextTick(()=> {
          this.movescreen(this.$route.params.id)
      })
    },
    toggleDropdown(){
      document.getElementById('dropdown').classList.toggle('hidden')
      document.getElementById('arrowhitam').classList.toggle('hidden')
      document.getElementById('arrowungu').classList.toggle('hidden')
    },
    stopOverflow(){
      $('#openNav').toggleClass('hidden');
      $('#mobile-menu').toggleClass('hidden')
    },
  },
  mounted(){
    if(this.navbaron != null){
      this.setnavbar(this.navbaron);
    }

    this.moveup()
  
    if(document.getElementById('arrowhitam')){
      document.getElementById('arrowhitam').classList.remove('hidden')
    }
    if(document.getElementById('arrowungu')){
      document.getElementById('arrowungu').classList.remove('hidden')
      document.getElementById('arrowungu').classList.add('hidden')
    }

    document.querySelector('html').classList.remove('overflow-y-hidden')
  }
};

// references
// $(document).ready(function () {
//   $(".nav-toggler").each(function (_, navToggler) {
//     var target = $(navToggler).data("target");
//     $(navToggler).on("click", function () {
//       $(target).animate({
//         height: "toggle",
//       });
//     });
//   });
// });

</script>