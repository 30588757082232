<template>
  <div v-if="pageLoad">
    <div v-if="meetingAda">
        <div v-if="meetingMulai">
            <div class="relative text-5xl bg-purple-600 text-white text-center md:py-32 xxs:py-24 px-16 mb-4">
                <h1 id="judulevent" class="capitalize absolute text-white font-bold text-center text-xl top-6 left-1/2 w-full" style="transform: translateX(-50%)">Presentation Meeting Management</h1>
                <div v-if="waktuShow" class="mb-4 md:mt-0 text-center xxs:mt-4 xxs:text-3xl md:text-5xl">{{stringWaktu}}<button v-if="waktuSisa < 1" @click="backTimer()" class="text-purple-600 bg-white font-bold rounded-3xl px-3 text-xl md:w-2/12 xxs:w-auto mx-2 md:text-lg xxs:text-sm">Back</button></div>
                <div v-else class="mb-4 xxs:text-3xl text-center md:text-5xl">Silahkan tunggu admin memulai timer</div>
                <!-- <div v-if="!waktuShow" class="justify-center">
                    <button @click="startTimer(900)" class="text-purple-600 bg-white font-bold rounded-3xl px-3 text-xl md:w-2/12 xxs:w-auto mx-2 md:text-lg xxs:text-sm">15</button>
                    <button @click="startTimer(1800)" class="text-purple-600 bg-white font-bold rounded-3xl px-3 text-xl md:w-2/12 xxs:w-auto mx-2 md:text-lg xxs:text-sm">30</button>
                    <button @click="startTimer(2700)" class="text-purple-600 bg-white font-bold rounded-3xl px-3 text-xl md:w-2/12 xxs:w-auto mx-2 md:text-lg xxs:text-sm">45</button>
                    <input id="timeInput" type="number" title="enter untuk mulai" placeholder="menit" @keyup.enter="startTimerManual" class="text-purple-600 bg-white font-bold rounded-3xl pl-4 text-xl mx-2 md:w-auto xxs:w-10/12 md:text-lg xxs:text-sm">
                </div> -->
                <!-- <div v-if="waktuShow">
                    <button @click="alertChat()" class="text-purple-600 bg-white font-bold rounded-3xl px-3 md:text-lg md:w-auto xxs:w-auto mx-2 xxs:text-sm">Alert Time</button>
                    <button @click="clearLocalStorage()" class="text-purple-600 bg-white font-bold rounded-3xl px-3 md:text-lg md:w-auto xxs:w-auto mx-2 xxs:text-sm">Stop Time</button>
                </div> -->
            </div>
            <div class="rounded-3xl border-purple-600 border-2 h-full mx-4 p-4 mb-4">
                <div class="text-black font-bold text-3xl text-center mb-4">Chat</div>
                <div>
                    <div v-for="comment in comments" class="mb-2">
                        <span class="opacity-75 pr-4">{{comment.waktuPost}}</span>
                        <span class="text-purple-600 text-xl pr-2">{{comment.pengirim+': '}}</span>
                        <span class="text-black text-xl leading-8 break-words">{{comment.isi}}</span>
                    </div>
                </div>
                <!-- <div>
                    <input id="chatInput" type="text" placeholder="Chat disini..." class="bg-transparent border-2 border-slate-300 px-3 mt-3" @keyup.enter="sendChat">
                </div> -->
            </div>
        </div>
        <div class="text-purple-600 text-center text-3xl mt-5" v-else>
            <img src="@/assets/logo.png" alt="" class="mt-10 w-60 h-auto m-auto">
            Meeting akan dimulai pada {{this.jamMulaiVariable}}, mohon ditunggu
        </div>
    </div>
    <div class="text-purple-600 text-center text-3xl mt-5" v-else>
        <img src="@/assets/logo.png" alt="" class="mt-10 w-60 h-auto m-auto">
        Maaf, Link meeting anda salah, silahkan diperiksa kembali
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return{
            waktuSisa: 0,
            jamSisa: 0,
            menitSisa: 0,
            detikSisa: 0,
            stringWaktu: '',
            waktuShow: false,
            comments: [],
            meetingAda: false,
            meetingMulai: false,
            pageLoad: false,
            flag: true,

            //axios
            judulVariable: '',
            jamMulaiVariable: '',
            jamSelesaiVariable: '',
            detikMulaiVariable: 0,
            meetingInfoVariable: []
        }
    },
    methods: {
        updateTimer(){
            this.jamSisa = Math.floor(this.waktuSisa/3600),
            this.menitSisa = Math.floor((this.waktuSisa%3600)/60),
            this.detikSisa = Math.floor(this.waktuSisa%60)

            if(this.jamSisa < 10){
                this.jamSisa = "0"+this.jamSisa;
            }
            if(this.menitSisa < 10){
                this.menitSisa = "0"+this.menitSisa;
            }
            if(this.detikSisa < 10){
                this.detikSisa = "0"+this.detikSisa;
            }

            this.stringWaktu = "Waktu Tersisa "+this.jamSisa+":"+this.menitSisa+":"+this.detikSisa;
            console.log('waktu sisa function'+  this.waktuSisa)
            if(this.waktuSisa <= 0){
                this.stringWaktu = "Waktu Meeting Habis"
                clearInterval(this.interval);
                clearInterval(this.interval2);
            }
            console.log(this.waktuSisa)
            if(this.waktuSisa == 900 || this.waktuSisa == 600 || this.waktuSisa == 300 || this.waktuSisa == 60){
                this.notificationMessage(this.stringWaktu);
                this.alertChat()
            }
        },
        reduceTimer(){
            if(this.waktuSisa > 0){
                this.waktuSisa -= 1
            }
            this.updateTimer()
        },
        startTimer(angkatimer){
            this.waktuSisa = angkatimer
            this.updateTimer()
            this.waktuShow = true
            this.interval = setInterval(() => {
                this.reduceTimer()
            }, 1000)
            this.interval2 = setInterval(() => {
                this.refreshTimer()
            }, 2000)
        },
        alertChat(){
            var date = new Date;
            var jamPost = date.getHours()
            var menitPost = date.getMinutes()
            if(menitPost < 10){
                menitPost = "0"+menitPost;
            }
            if(jamPost < 10){
                jamPost = "0"+jamPost;
            }
            var waktuPostString = jamPost + ':' + menitPost
            if(this.waktuSisa >= 1){
                this.comments.push({isi: this.stringWaktu, pengirim: 'System', waktuPost: waktuPostString})
            }else{
                this.comments.push({isi: 'Waktu Presentasi Habis', pengirim: 'System', waktuPost: waktuPostString})
            }
        },
        sendChat(){
            var isichat = document.getElementById('chatInput').value
            var date = new Date;
            var jamPost = date.getHours()
            var menitPost = date.getMinutes()
            if(menitPost < 10){
                menitPost = "0"+menitPost;
            }
            if(jamPost < 10){
                jamPost = "0"+jamPost;
            }
            var waktuPostString = jamPost + ':' + menitPost
            if(isichat != ''){
                this.chat(isichat, waktuPostString)
            }
        },
        chat(isichat, waktuPostString){
            this.comments.push({isi: isichat, pengirim: 'Superadmin', waktuPost: waktuPostString})
            document.getElementById('chatInput').value = ''
        },
        clearLocalStorage(){
            this.waktuSisa = -1
            this.updateTimer()
        },
        backTimer(){
            this.waktuShow = false
		},
        notificationMessage(message) {
            if(Notification.permission == 'granted'){
                this.showNotification(message)
            }else if(Notification.permission != 'denied'){
                Notification.requestPermission().then(permission => {
                    if(permission == 'granted'){
                        this.showNotification(message);
                    }
                })
            }
        },
        showNotification(message) {
            var notification = new Notification("Sisa Waktu presentasi", {
                body: message,
                icon: require('@/assets/logo.png')
            });
        },
        notificationMessageManual(message, pengirim) {
            if(Notification.permission == 'granted'){
                this.showNotificationManual(message, pengirim)
            }else if(Notification.permission != 'denied'){
                Notification.requestPermission().then(permission => {
                    if(permission == 'granted'){
                        this.showNotificationManual(message, pengirim);
                    }
                })
            }
        },
        showNotificationManual(message, pengirim) {
            var notification = new Notification(pengirim, {
                body: message,
                icon: 'https://img.icons8.com/office/36/000000/person-female.png'
            });
        },
        refreshTimer(){
            if(this.meetingMulai){
                const date_timer = new Date(this.meetingInfoVariable.waktu_timer)
                const waktu_user = (this.meetingInfoVariable.jumlah_menit * 60) - Math.floor((new Date().getTime() - date_timer.getTime()) / 1000)

                if(this.flag){
                    this.flag = false
                    if(this.meetingInfoVariable.waktu_timer != null){
                        this.startTimer(waktu_user)
                    }
                }else{
                    if(waktu_user > 0){
                        // console.log('ter refresh')
                        this.waktuSisa = waktu_user
                    }
                }
            }
        }
    },
    mounted(){
        this.meetingMulai = false
        this.meetingAda = false

        axios.get(`meeting_management`)
            .then(res=>{
                this.pageLoad = true
                var temporaryvar = res.data.filter((meeting)=>{
                    if(meeting.kode == this.$route.params.kode) return meeting.kode
                })
                if(temporaryvar.length > 0){
                    this.meetingAda = true
                    this.meetingInfoVariable = temporaryvar[0]
                    this.judulVariable = this.meetingInfoVariable.nama_event.replaceAll('-', ' ')
                    this.jamMulaiVariable = this.meetingInfoVariable.jam_mulai
                    this.jamSelesaiVariable = this.meetingInfoVariable.jam_selesai

                    const date_mulai = new Date(this.meetingInfoVariable.jam_mulai)
                    const total_detik_mulai = date_mulai.getTime()
                    this.detikMulaiVariable = total_detik_mulai

                    const date_selesai = new Date(this.meetingInfoVariable.jam_selesai)
                    const total_detik_selesai = date_selesai.getTime()

                    var currentTime = new Date().getTime()
                    if(currentTime >= total_detik_mulai && currentTime <= total_detik_selesai){
                        console.log('waktu aktif')
                        this.meetingMulai = true
                    }

                    this.refreshTimer()
                }else{
                    this.meetingAda = false
                    console.log('link salah')
                }
            })


        window.Echo.channel('timerChannel')
            .listen('getTimerEvent', (e) =>{
                console.log(this.meetingInfoVariable)
                if(e.id == this.meetingInfoVariable.id){
                    this.flag = false
                    this.startTimer(e.sisa_waktu)
                }
                console.log('ws masuk')
            });

        window.Echo.channel('commentChannel')
            .listen('commentPMM', (e) =>{
                console.log(e)
                if(this.meetingInfoVariable.id == e.event_id){
                    this.comments.push({isi: e.isi_message, pengirim: e.pengirim, waktuPost: e.waktu_kirim})
                    this.notificationMessageManual(e.isi_message, e.pengirim);
                }
                console.log('listenedcomment')
            });
    },
    updated(){
        axios.get(`meeting_management`)
            .then(res=>{
                var temporaryvar = res.data.filter((meeting)=>{
                    if(meeting.kode == this.$route.params.kode) return meeting.kode
                })
                this.meetingInfoVariable.waktu_timer = temporaryvar[0].waktu_timer
            })
    }
}
</script>

<style>

</style>