<template>
  <div title="Scroll to Top">
    <div class="fixed bottom-4 right-4 hover:cursor-pointer w-12 h-12 rounded-full border-4 border-violet-600 transition-all duration-200 hover:w-14 hover:h-14 hover:bottom-3 hover:right-3 z-10 bg-white" @click="scrollToTop()">
      <img class="rotate-90 w-full h-full" src="@/assets/arrow-v.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        scrollToTop(){
            window.scrollTo(0, 0)
        }
    }
}
</script>

<style>

</style>