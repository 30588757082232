<template v-if="listAlbum">
  <div class="xxs:px-3 md:px-4 py-8">
    <div class="flex flex-wrap md:justify-between xxs:justify-center md:px-4">
        <h1 class="lg:text-4xl md:text-2xl sm:text-3xl xxs:text-xl font-bold pb-3 md:w-1/2 xxs:w-full">List Album</h1>
        <div class="flex items-center self-start pb-4 xxs:w-full md:w-1/2">
            <input class="rounded-3xl border-2 mr-2 bg-transparent focus:border-purple-600 focus:border-2 border-purple-600 px-4" type="text" v-model="data" @keydown="checkInput" @keyup.enter="findAlbum" placeholder="Tekan enter untuk cari album">
        </div>
    </div>
    <div class="bgimage fixed opacity-50 bottom-0 left-0 w-full h-full -z-10"></div>
    <div class="flex flex-wrap" v-if="!isSearching">
        <div v-for="album in listAlbum" :key="album.id" class="transition-all flex flex-wrap justify-center border-2 border-purple-200 duration-300 hover:bg-violet-600 hover:text-white album cursor-pointer" @click="albumDetails(album.id, album.title)">
            <!-- <p class="text-center text-xl font-bold break-words h-16">{{album.title.split('_').join(' ')}}</p> -->
            <div class="w-full xxs:h-32 xs:h-48 sm:h-36 md:h-52 lg:h-44 xl:h-56 2xl:h-64 bg-cover bg-no-repeat bg-center" :style="'background-image: url('+album.image+')'"></div>
            <p class="text-center py-2 px-2 xl:text-base xs:text-sm xxs:text-xs font-semibold break-words">{{coba(album.title)}}</p>
        </div>
    </div>
    
    <div id="noData" v-if="!isData">Tidak ada Album yang bernama {{ data }}</div>
    
    <div class="flex flex-wrap" v-else>
        <div v-for="album in albumData" :key="album.id" class="transition-all flex flex-wrap justify-center border-2 border-purple-200 duration-300 hover:bg-violet-600 hover:text-white album cursor-pointer" @click="albumDetails(album.id, album.title)">
            <!-- <p class="text-center text-xl font-bold break-words h-16">{{album.title.split('_').join(' ')}}</p> -->
            <div class="w-full xxs:h-32 xs:h-48 sm:h-36 md:h-52 lg:h-44 xl:h-56 2xl:h-64 bg-cover bg-no-repeat bg-center" :style="'background-image: url('+album.image+')'"></div>
            <p class="text-center py-2 px-2 xl:text-base xs:text-sm xxs:text-xs font-semibold break-words">{{coba(album.title)}}</p>
        </div>
    </div>
  </div>
</template>

<style scoped>
.bgimage{
    background-image: url('@/assets/beritabg.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.bgima{
    background-image: url('@/assets/logo.png')
}
.kategori{
    background: linear-gradient(240deg, #C56FE1 0%, #CB81F2 15%, #8F41F1 100%);
    border-radius: 12px;
    color: white;
    padding: 4px 12px;
    height: 40px;
    margin: 0 12px 24px 0;
    text-align: center;
}

.album{
    max-width: 31.3%;
    flex: 0 0 31.3%;
    margin: 10px;
    background-color: #fff;
    border-radius: 6px;
}


@media (max-width: 1020px) {
    .album{
        max-width: 46%;
        flex: 0 0 46%;
    }
}

@media (max-width: 576px) {
    .album{
        max-width: 95%;
        flex: 0 0 95%;
    }
}
</style>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import axios from 'axios';

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data(){
        return {
            data: '',
            isSearching: false,
            isData: true,
            isError: false,
            errMsg: '',
            albumData: [],
            listAlbum: []
        }   
    },
    methods: {
        getAlbums(){
            this.isSearching = false
            this.albumData = [];
            this.isError = false;
            
            axios.get(`albums`)
                .then(res=>{
                    this.listAlbum = res.data
                })
                .catch(err => {
                    this.isError = true
                    this.errMsg = err.message
                })
        },
        findAlbum(){
            if(this.data){
                this.isSearching = true;
                this.albumData = [];
                this.isData = true;
                this.isError = false;
                
                axios.get(`albums/${this.data}`)
                    .then(res => {
                        this.albumData = res.data
                    })
                    .catch(err => {
                        this.isData = false
                        this.isError = true
                        this.errMsg = err.message
                    })
            }else{
                console.log('tidak ada input')
                this.isData = true
                this.getAlbums()
            }
        },
        albumDetails(id,title){
            localStorage.idAlbum = id
            this.$router.push({name: 'AlbumDetails', params: { id, title}})
        },
        checkInput(){
            if(this.data.length < 2){
                this.isSearching = false
                this.getAlbums();
            }
        },
        coba(value){
            var strSplit = [];
            value.split('_').forEach((data, index) => {
                strSplit[index] = data.charAt(0).toUpperCase() + data.substring(1);
            })
            
            return strSplit.join(' ');
        }
    },
    created(){
        this.getAlbums();
    },
}
</script>