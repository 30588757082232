<template v-if="albumItems">
  <div class="">
    <div class="px-4 bgimage fixed opacity-50 bottom-0 left-0 w-full h-full -z-10"></div>
    <div class="md:flex items-center" >
        <div ref="iframeref" class="md:px-4 xxs:px-2 w-full self-center sm:mb-4 xxs:mb-2">
            <h1 class="xxs:px-2 xxs:py-4 lg:p-4 xl:text-3xl md:text-2xl xxs:text-xl font-bold">Album Details</h1>
            <div ref="iframeref2" class="flex items-center justify-center w-full mb-2 gallery-img">
                <img ref="leftimage" src="" alt="Gambar" class="m-auto">
                <iframe ref="leftlink" src="" class="w-full xxs:h-48 xs:h-56 sm:h-72 md:h-56 lg:h-72 md:w-full m-auto"></iframe>
            </div>
            <p class="text-center xl:text-lg md:text-base xxs:text-sm font-semibold">{{ title() }}</p>
            <p class="text-center 2xl:text-base xl:text-sm sm:text-sm xxs:text-xs xxs:mb-2 md:mb-4">{{ description }}</p>
            <router-link :to="{ name: 'Album' }" style="background-color: #9333EA" class="w-full m-auto py-1 rounded-3xl bg-black text-white xl:text-lg md:text-base xxs:text-sm font-bold text-center">
                Back to home
            </router-link>
        </div>
        <div class="w-full md:h-screen md:rounded-l-2xl p-2 pb-14" :class="albumItems.length < 7 ?  'xxs:h-45' : 'xxs:h-45'" style="background: linear-gradient(15deg, #C56FE1 0%, #CB81F2 50%, #8F41F1 100%)">
            <p class="text-white lg:text-2xl md:text-xl xxs:text-base font-semibold text-center py-2 px-4">Album Content</p>

            <div class="flex flex-wrap content-start ease-in-out xxs:overflow-y-scroll md:h-5/6" :class="albumItems.length < 7 ? 'xxs:h-4/6' : 'xxs:h-5/6' " >
                <div v-for="gambar in albumItems" :key="gambar.id" class="xl:w-1/3 lg:w-1/2 md:w-full flex content-center xxs:w-1/2">
                    <div class="xxs:p-2 sm:w-full h-full flex items-center" v-if="gambar.type == 'image'" @click="changeAlbumItem(gambar.src, gambar.type)" >
                        <img class="w-full" :src="gambar.src" alt="src" >
                    </div>
                    <div class="relative md:w-full w-full h-auto flex items-center video videoDetail" v-else-if="gambar.type == 'video'" @click="changeAlbumItem(gambar.src, gambar.type)" >
                        <img class="w-full" :src="'http://img.youtube.com/vi/'+gambar.videoid+'/default.jpg'" alt="">
                        <a href="javascript: void(0);"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    props: ['albumItems', 'title', 'description'],
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    methods: {
        changeAlbumItem(newsrc, type){
            this.$refs.leftimage.style.display = "none"
            this.$refs.leftlink.style.display = "none"
            if(type=="image"){
                this.$refs.leftimage.src = newsrc
                this.$refs.leftimage.style.display = "block"
            }else if(type == "video"){
                this.$refs.leftlink.src = newsrc
                this.$refs.leftlink.style.display = "block"
            }
        },
        updateGalleryImage: function(){
            this.$refs.leftimage.style.display = "none"
            this.$refs.leftlink.style.display = "none"
            if(this.albumItems[0].type == "image"){
                this.$refs.leftimage.style.display = "block"
                this.$refs.leftimage.src = this.albumItems[0].src
            }else if(this.albumItems[0].type == "video"){
                this.$refs.leftlink.style.display = "block"
                this.$refs.leftlink.src = this.albumItems[0].src
            }
        },
        title(){
            var strSplit = [];
            this.title.split('_').forEach((data, index) => {
                strSplit[index] = data.charAt(0).toUpperCase() + data.substring(1);
            })
            
            return strSplit.join(' ');
        }
    },
    updated(){
        this.updateGalleryImage()
    }
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #7e22ce;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7e22ce;
}

.bgimage{
    background-image: url('@/assets/beritabg.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.kategori{
    background: linear-gradient(240deg, #C56FE1 0%, #CB81F2 15%, #8F41F1 100%);
    border-radius: 12px;
    color: white;
    padding: 4px 12px;
    height: 40px;
    margin: 0 12px 24px 0;
    text-align: center;
}

.gallery-img{
    aspect-ratio: auto 1/1;
    object-fit: contain;
}
</style>