<template v-if="!!listBerita">
  <div class="xxs:p-2 md:p-4 lg:p-8">
    <h1 class="lg:text-4xl md:text-2xl sm:text-3xl xxs:text-xl xxs:p-2 md:p-0 font-bold">List Berita</h1>
    <div class="bgimage fixed opacity-50 bottom-0 left-0 w-full h-full -z-10"></div>
    <div class="xxs:p-2">
        <div class="lg:flex md:flex lg:w-5/6 xxs:w-full xxs:mb-2 sm:mb-4 md:mb-8 cursor-pointer hover:bg-gray-200 xxs:p-1 md:p-4 transition-all duration-200" @click="beritaDetails(berita.id, berita.slug)" v-for="berita in listBerita" :key="berita.id">
            <div class="lg:w-5/12 md:w-6/12 lg:mr-16 md:mr-8 sm:w-full berita-img sm:mr-8 xxs:w-full xxs:mr-8 flex justify-center">
                <img :src="berita.image" alt="">
            </div>
            <div class="lg:w-7/12 xxs:mt-4 md:mt-0 md:w-6/12 sm:w-full xxs:w-full">
                <div class="flex w-11/12">
                    <div class="kategori mx-1 md:text-sm xxs:text-xs" v-for="category in berita.categories" :key="category.id">
                        {{category}}
                    </div>
                </div>
                <div>
                    <p class="text-gray-800 font-bold lg:text-xl sm:text-base xxs:text-sm pt-1 title-news">{{berita.title}}</p>
                    <p class="text-gray-700 font-semibold md:text-sm py-1 xxs:text-xs">{{berita.datetime}}</p>
                    <div v-if="(berita.description).length > 70">
                        <span v-html="berita.description.slice(0,70) + '...'" class="deskripsi text-gray-700 break-words"></span>
                    </div>
                    <div v-else>
                        <span v-html="berita.description" class="text-gray-700 deskripsi"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<style scoped>
    .deskripsi /deep/ p{
        font-size: 16px;
        line-height: 1.25rem;
    }

    .berita-img{
        object-fit: contain;
        aspect-ratio: auto 3/2;
    }

    @media (max-width: 576px) {
        .deskripsi /deep/ p{
            font-size: 12px!important;
        }
    }

    @media (min-width: 1400px){
        .deskripsi /deep/ p{
            font-size: 18px!important;
            line-height: 1.5rem;
        }
    }

    @media (min-width: 1560px){
        .deskripsi /deep/ p{
            font-size: 20px!important;
            line-height: 2rem;
        }
    }
</style>

<script>
import axios from 'axios';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    props: ['listBerita'],
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    methods: {
        beritaDetails(id, slug){
            localStorage.idBerita = id
            this.updateBerita(id)
            this.$router.push({name: 'BeritaDetails', params: {id,slug}})
        },
        updateBerita(id){
            axios.put(`berita/${id}`)
            .catch(err => {
                console.log(err.message)
            })
        }
    }
}
</script>

<style scoped>
.bgimage{
    background-image: url('@/assets/beritabg.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.kategori{
    background: linear-gradient(240deg, #C56FE1 0%, #CB81F2 15%, #8F41F1 100%);
    border-radius: 6px;
    color: white;
    padding: 6px 12px;
    text-align: center;
}

.title-news{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>