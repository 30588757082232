<template>
  <Navbar :whitetheme="whitetheme" navbaron="album"/>
  <AlbumDetail :albumItems="albumItems" :title="albumTitle" :description="albumDescription"/>
  <ScrollToTop />
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import AlbumDetail from '@/components/ComponentAlbum/AlbumDetail.vue';
import ScrollToTop from '@/components/ScrollToTop.vue';
import axios from 'axios';

export default {
  data(){
    return{
      whitetheme: true,
      albumItems: [],
      albumDescription: '',
      albumTitle: ''
    }
  },
  components:{
    Navbar,
    AlbumDetail,
    ScrollToTop,
  },
  methods: {
    scrollup(){
      window.scroll({
          top: 0,
          left: 0,
      })
    } 
  },
  beforeRouteEnter(to, from, next){
    if(!localStorage.idAlbum){
      axios.get(`album/${to.params.id}`)
      .then(res => {
        next(vm => {
            localStorage.idAlbum = to.params.id;
            vm.albumItems = res.data.album_media
            vm.albumDescription = res.data.description
            vm.albumTitle = res.data.title
          })
      })
      .catch(err => {
        router.push("NotFound")
        console.log(err.message)
      })
    }else{
      axios.get(`album/${localStorage.idAlbum}`)
      .then(res => {
        next(vm => {
            vm.albumItems = res.data.album_media
            vm.albumDescription = res.data.description
            vm.albumTitle = res.data.title
          })
      })
      .catch(err => {
        console.log(err.message)
      })
    }
  },
  mounted(){
      this.scrollup()
  },
  beforeRouteLeave(){
    localStorage.removeItem('idAlbum')
  }
}
</script>